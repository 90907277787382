<template>
  <div class="flex flex-col">
    <div class="text-xl font-bold">Renungan dan Informasi terkini</div>
    <div class="flex items-end space-x-1">
      <MaterialInput
        class="w-full"
        v-model="search"
        placeholder="Cari"
      />
      <MaterialButton
        @click="submitSearch"
      >
        <img class="w-8" src="~@/assets/icons/paper-plane-right.svg" alt="paper-plane">
      </MaterialButton>
      <MaterialButton
        @click="resetFilter"
      >
        <img class="w-8" src="~@/assets/icons/arrow-u-up-left.svg" alt="back">
      </MaterialButton>
    </div>
    <div id="articleSection">
      <ArticleCard
        v-for="article in articles"
        :key="article.id"
        :article="article"
      />
    </div>
    <paginate
      :default-value="page"
      :page-count="totalPage"
      :click-handler="onChangePage"
      :margin-pages="2"
      :page-range="3"
      :container-class="'pagination'"
      :page-class="'page-item'"
      :page-link-class="'page-link-item'"
      :prev-class="'prev-item'"
      :prev-link-class="'prev-link-item'"
      :next-class="'next-item'"
      :next-link-class="'next-link-item'"
      :break-view-class="'break-view'"
      :break-view-link-class="'break-view-link'"
      :active-class="'active'"
      :disabled-class="'disabled'"
    ></paginate>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapActions } from 'vuex'
import { homeConfig } from '@/config'
const ArticleCard = defineAsyncComponent(() => import('@/components/home/ArticleCard.vue'))
const MaterialInput = defineAsyncComponent(() => import('@/components/common/MaterialInput.vue'))
const MaterialButton = defineAsyncComponent(() => import('@/components/common/MaterialButton.vue'))
const Paginate = defineAsyncComponent(() => import('@/components/common/Paginate.vue'))
export default {
  name: 'Article',
  components: {
    ArticleCard,
    MaterialInput,
    MaterialButton,
    Paginate
  },
  data () {
    return {
      search: '',
      page: 1
    }
  },
  created () {
    this.showLoading()
    if (Object.keys(this.queryParams).length === 0) {
      this.$router.replace({
        path: this.$route.path,
        query: { ...homeConfig.articleDefaultQueryParams }
      })
    } else {
      this.search = this.queryParams.search
      this.page = this.queryParams.page
    }
    this.fetchArticle()
  },
  computed: {
    ...mapState('article', ['articles', 'pageInfo']),
    queryParams () {
      return this.$route.query
    },
    totalRecords () {
      return this.pageInfo.totalRecords > 200 ? 200 : this.pageInfo.totalRecords
    },
    totalPage () {
      return Math.ceil(this.totalRecords / 10)
    },
    topElement () {
      const element = document.getElementById('articleSection')
      return element.offsetTop
    }
  },
  methods: {
    ...mapActions('article', ['fetch']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    fetchArticle () {
      this.fetch({
        queryParams: this.queryParams,
        success: this.hideLoading,
        fail: this.hideLoading
      })
    },
    submitSearch () {
      this.$router.replace({
        path: this.$route.path,
        query: { ...homeConfig.articleDefaultQueryParams, search: this.search }
      })
    },
    resetFilter () {
      this.search = ''
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.queryParams, search: '' }
      })
    },
    onChangePage (page) {
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.queryParams, page }
      })
      window.scrollTo(0, this.topElement)
    }
  },
  watch: {
    queryParams: {
      handler () {
        this.fetchArticle()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
